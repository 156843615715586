<template>
  <div>
    <v-card class="card pa-3 mx-3" >
      <v-row>
        <v-col class="text-container">
          <span class="medium-font" v-text="text_copy">
          </span>
        </v-col>
      </v-row>
      <v-row class="pa-2 d-flex flex-column align-center">
        <div v-if="copy" class="copy-alert py-2 px-3 mb-3">
          <span class="medium-font white--text">کپی شد</span>
        </div>
        <v-btn @click="copyStringToClipboard(text_copy)" class="button primary" small>کپی متن</v-btn>
      </v-row>
    </v-card>
  </div>
</template>
<style lang="scss" scoped>
.text-container{
  display: block;
  white-space: break-spaces;
}

.copy-alert{
  width: 90px;
  height: fit-content;
  background-color: #00000094;
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 155px;
  z-index: 300;
  border-radius: 7px;

}
</style>
<script>
export default {
  data: () => ({
    copy: false,
    text_copy: 'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم'
  }),
  methods: {
   copyStringToClipboard (str) {
   // Create new element
   const el = document.createElement('textarea');
   // Set value (string to be copied)
   el.value = str;
   // Set non-editable to avoid focus and move outside of view
   el.setAttribute('readonly', '');
   el.style = {position: 'absolute', left: '-9999px'};
   document.body.appendChild(el);
   // Select text inside element
   el.select();
   // Copy text to clipboard
   document.execCommand('copy');
   // Remove temporary element
   document.body.removeChild(el);
   this.copy = true
   setTimeout(() => {
     return this.copy = false
   }, 1000);
}
  }
}
</script>