<template>
<div id="inviteFriends-panel">
  <v-expansion-panels v-for="list in invite_list" :key="list.Customer_ID" flat>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <v-row>
          <v-col>
            <v-avatar size="30">
              <v-img :src="list.Photo_Path"/>
            </v-avatar>
            <span class="medium-font font-weight-bold mx-2" v-text="list.Nick_Name"></span>
          </v-col>
          <v-col class="d-flex align-center justify-end">
            <div class="mx-2">
              <span class="medium-font font-weight-bold mx-2">خرید :</span>
              <span class="medium-font font-weight-bold ">3 بار</span>
            </div>
          </v-col>
        </v-row>
        <template v-slot:actions>
          <v-icon color="primary">
            $expand
          </v-icon>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row>
          <v-col cols="12" md="6">
            <v-card class="pa-3 card flat-card" :color="color">
              <v-row>
                <v-col cols="12" md="6" class="d-flex align-center">
                  <span class="medium-font">امتیاز :</span>
                  <div class="d-flex align-center">
                    <v-icon size="19" class="info--text mx-1">mdi-diamond-stone</v-icon>
                    <span class="medium-font">110</span>
                  </div>
                </v-col>
                <v-col cols="12" md="6">
                  <span class="medium-font">لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ</span>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-card class="pa-3 card flat-card " :color="color">
              <v-row>
                <v-col cols="12" md="6" class="d-flex align-center">
                  <span class="medium-font">کیف پول بهمن 99 :</span>
                  <div>
                    <v-icon size="19" class="info--text mx-1">mdi-wallet-giftcard</v-icon>
                    <span class="medium-font">200,000 تومان</span>
                  </div>
                </v-col>
                <v-col cols="12" md="6">
                  <span class="medium-font">لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ</span>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <!-- <v-card class="pa-3 card flat-card" :color="color">
          <v-row>
            <v-col>
              <span class="font">Rate :</span>
              <span class="font mx-1">110</span>
            </v-col>
          </v-row>
        </v-card>
        <v-card class="pa-3 card flat-card mt-2" :color="color">
          <v-row>
            <v-col>
              <span class="font">Wallet :</span>
              <span class="font mx-1">$59.00</span>
            </v-col>
          </v-row>
        </v-card> -->
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: ['color'],
  computed:{
    ...mapState({
      invite_list: state => state.user.invite_list
    })
  }
}
</script>